<template>
  <v-card flat>
    <template>
      <v-card-title class="pa-1" v-if="payload.isLivePagination">
        <barcode-scanner v-if="payload.showScanCode" v-model="search" :search="search" :scannerPayload="scannerPayload" :loading="scannerFieldLoading"></barcode-scanner>
        <v-text-field v-else solo :label="$t('message.common.search')" class="pa-0" v-model="search" hide-details dense></v-text-field>
      </v-card-title>
      <v-card-title class="pa-0">
        <v-btn x-small fab @click="prevPage" :disabled="page === 1"><!-- payload.isLivePagination ? prevList :  -->
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn color="primary" v-if="payload.addBtnTo && checkActionPermission(payload.module, CONSTANTS.CREATE)" fab x-small id="newbtnTo" :to="payload.addBtnTo" class="ml-1">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <!-- <v-btn color="primary" v-if="!payload.addBtnTo && payload.addClickHandler" fab x-small id="newbtnClick" @click="payload.addClickHandler" class="ml-3">
          <v-icon>mdi-plus</v-icon>
        </v-btn> -->
        <v-spacer></v-spacer>
        <h5>{{ payload.isCustomModules ? payload.moduleTitle : $t(payload.moduleTitle) }}</h5>
        <v-spacer></v-spacer>
        <span class="mr-2 subtitle-2">{{ payload.total > 0 ? `${paginationInfo} ${$t('message.common.of')} ${payload.total}` : ''}}</span>
        <v-btn x-small fab @click="nextPage" :disabled="payload.hasNoNext"><!-- payload.isLivePagination ? nextList :  -->
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-card-title>
    </template>
    <v-card-text class="pa-1 pt-0 mt-2 mb-4">
      <div class="text-center" v-if="payload.loading">
        <v-progress-linear :active="payload.loading" :indeterminate="payload.loading" color="primary"></v-progress-linear>
      </div>
      <v-card outlined color="#e8eef0" class="pa-0 mt-0" v-if="payload.itemHeaders && payload.list && payload.list.length">
        <v-row class="ma-0">
          <template v-for="(header, index) in payload.itemHeaders">
            <v-col v-if="header.labels" class="pa-1" :cols="header.cols" :key="index">
              <v-row class="ma-0" v-if="header.labels">
                <v-col class="pa-0" v-for="(label, labelIndex) in header.labels" :key="labelIndex">
                  <strong>{{$t(label)}}</strong>
                </v-col>
              </v-row>
            </v-col>
            <v-col v-else-if="typeof header === 'string'" :cols="payload.items[index] && payload.items[index].cols ? payload.items[index].cols : ''" :class="payload.items[index] && payload.items[index].class ? payload.items[index].class : ''" :key="index">
              <strong>{{$t(header)}}</strong>
            </v-col>
          </template>
        </v-row>
      </v-card>
      <template v-for="(item, listIndex) in payload.list">
        <v-card outlined color="#e8eaf6" class="pa-0 py-1 mt-1" :key="listIndex" @click.stop="checkActionPermission(payload.module, CONSTANTS.EDIT) ? payload.editHandler(item) : ''">
          <v-card-text class="pa-0 py-0">
            <v-row>
              <template>
                <template v-for="(colValues, index) in payload.items">
                  <template v-if="colValues.value">
                    <v-col :cols="colValues.cols" :class="colValues.class" :key="index">
                      <div v-if="colValues.projectColor && item[colValues.colorValue]" class="mr-1" :style="`float:left; width: 20px; height: 20px;background-color: ${item[colValues.colorValue]};`">
                      </div>
                      <div v-if="colValues.isParagraph" class="custom-height">
                        {{item[colValues.value]}}
                      </div>
                      <span v-else :class="colValues.spanClass">{{ item[colValues.value] }}</span>
                    </v-col>
                  </template>
                  <template v-else>
                    <v-col :cols="colValues.cols" :class="colValues.class" :key="index">
                      <v-row :class="colValues.rowClass">
                        <template v-for="(subitems, i) in colValues.subItems">
                          <v-col :cols="subitems.cols" :class="subitems.class" :key="i">
                            <template v-if="subitems.value === 'is_completed'">
                              <v-select class="px-0 pr-2" v-if="payload.moduleTitle === 'message.layout.drawing'" dense hide-details @click.stop="" @change="saveStatusUpdate(listIndex)" v-model="item[subitems.value]" item-text="text" item-value="value" :items="subitems.items" label="Status" outlined ></v-select>
                             <template v-else>
                              <span :class="subitems.spanClass" v-if="!payload.itemHeaders"><strong>{{ $t('message.projects.status') }}:</strong> </span><br v-if="!payload.itemHeaders" />
                              <span :class="subitems.spanClass">{{item[subitems.value] === 1 ? $t('message.customModule.completed') : $t('message.customModule.notCompleted') }}</span>
                             </template>
                            </template>
                            <template v-else-if="subitems.isTranslatable">
                              <span :class="subitems.spanClass">{{ $t(item[subitems.value]) }}</span>
                            </template>
                            <span v-else :class="subitems.spanClass">{{item[subitems.value]}}</span>
                          </v-col>
                        </template>
                      </v-row>
                    </v-col>
                  </template>
                </template>
              </template>
              <v-col v-if="payload.actionSize" :cols="payload.actionSize" class="pa-0 pr-1 my-auto">
                <v-layout row wrap justify-center class="mx-0">
                  <v-btn max-height="24" max-width="24" icon v-if="!payload.isChecklist && payload.hasDelete && checkActionPermission(payload.module, CONSTANTS.DELETE)" @click.stop="deleteRecord(item.id)" color="error">
                    <v-icon>mdi-delete-circle</v-icon>
                  </v-btn>
                </v-layout>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
      <v-alert prominent text v-if="!payload.loading && (payload.list.length === 0)" type="info" class="mt-2" >
        <v-row align="center">
          <v-col class="grow">{{ $t('message.common.noResults') }}</v-col>
        </v-row>
      </v-alert>
    </v-card-text>
  </v-card>
</template>
<script>
import PermissionsMixin from '@/mixins/permissions'
import { mapGetters } from 'vuex'
import { hostAppApi } from '@/plugins/axios_settings'
export default {
  mixins: [PermissionsMixin],
  props: ['payload'],
  data () {
    return {
      selectStatus: null,
      hostRef: this.$cookie.get('WelditPwa_host-ref'),
      loading: false,
      page: 1,
      recordPerPage: 6,
      createPermission: true,
      search: '',
      debouncedSearch: null,
      recordsInfo: '',
      /* Scanner field */
      scannerPayload: {
        placeholder: 'message.mixed.scanSerialNumber',
        change: this.getRespectiveEquipment,
        attrRef: 'scannerFieldFocus',
        attrId: 'scannerField'
      },
      scannerFieldLoading: false
    }
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId']),
    paginationInfo () {
      return this.recordsInfo
    }
  },
  components: {
    'barcode-scanner': () => import('@/components/BarQrCodeScanner')
  },
  mounted () {
    this.$eventBus.$on('changePage', page => {
      this.page = page
      this.changePage(page)
    })
    // search after some ms
    this.debouncedSearch = this.$formatter.debounce(() => {
      this.$eventBus.$emit('loadList', { page: this.page, search: this.search, itemsPerPage: this.recordPerPage })
    }, 750)
    this.calculatePagination(this.recordPerPage)
  },
  methods: {
    saveStatusUpdate (index) {
      const moduleId = this.payload.list[index].id
      const header = { 'Content-Type': 'multipart/form-data' }
      const formData = new FormData()
      const url = `https://${this.hostRef}/api/project_drawing/${moduleId}/drawing_status`
      formData.append('is_completed', this.payload.list[index].is_completed)
      formData.append('id', moduleId)
      formData.append('project_id', this.$route.params.project_id)
      hostAppApi.post(url, formData, { headers: header })
        .then((response) => {
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: response.data.message })
        })
    },
    prevPage () {
      if (this.payload.isLivePagination) this.previousList()
      else if (this.page > 1) {
        this.page--
        this.changePage(this.page)
      }
    },
    nextPage () {
      if (this.payload.isLivePagination) this.nextList()
      else if (this.page < this.numPages()) {
        this.page++
        this.changePage(this.page)
      }
    },
    changePage (page) {
      this.payload.list = []
      if (page < 1) page = 1
      if (page > this.numPages()) page = this.numPages()
      for (var i = (page - 1) * this.recordPerPage; i < (page * this.recordPerPage) && i < this.payload.completeList.length; i++) {
        this.payload.list.push(this.payload.completeList[i])
      }
      if (page === 1) {
        this.payload.hasNoNext = false
      }
      if (page === this.numPages()) {
        this.payload.hasNoNext = true
      } else {
        this.payload.hasNoNext = false
      }
    },
    numPages () {
      const noOfPages = Math.ceil(this.payload.completeList.length / this.recordPerPage)
      return noOfPages < 1 ? 1 : noOfPages
    },
    deleteRecord (id) {
      this.$eventBus.$emit('deleteItems', { module: this.payload.module, id })
    },
    // Live pagination navigation methods
    nextList () {
      this.page++
    },
    previousList () {
      this.page--
    },
    calculatePagination (itemsPerPage) {
      var numItems = this.payload.total
      const payloadListLength = this.payload.list.length
      var showFrom = ((this.page - 1) * itemsPerPage) + 1
      var showTo = (showFrom + itemsPerPage)
      const to = (numItems < itemsPerPage && this.page === 1) ? payloadListLength : (showTo - 1) > numItems ? numItems : (showTo - 1)
      this.recordsInfo = `${showFrom} - ${to === 0 ? payloadListLength : to}`
    },
    /* For equipment list scan */
    getRespectiveEquipment () {
      if (this.search) {
        this.scannerFieldLoading = true
        hostAppApi.get(`${this.getHostRefApi}equipment/get_by_barcode/${this.search}`)
          .then((response) => {
            if (response && response.data && typeof response.data === 'number') {
              this.$router.push(`/equipments/${response.data}`)
            } else {
              this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: 'message.mixed.noEquipmentFound' })
            }
          })
          .finally(() => { this.scannerFieldLoading = false })
      }
    }
    /* --- */
  },
  watch: {
    search (val) {
      if (!this.payload.showScanCode) this.debouncedSearch()
    },
    page: {
      handler (val) {
        this.$eventBus.$emit('loadList', { page: val, search: this.search, itemsPerPage: this.recordPerPage })
        this.calculatePagination(this.recordPerPage)
      },
      deep: true
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('changePage')
  }
}
</script>
<style scoped>
  .custom-height {
    position: relative;
    width: 100%;
    max-height: 5.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 1px;
  }
</style>
